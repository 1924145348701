import { gql } from "@apollo/client";

export const _appInfo = gql`
  query AppInfo {
    appInfo {
      apiName
      apiVersion
      storageBucketVideoRoot
      storageBucketImageRoot
    }
  }
`;

export const _placeMultipleItemsPayment = gql`
  mutation PlaceMultipleItemsPayment($input: PlaceMultipleItemsPaymentIn) {
    placeMultipleItemsPayment(input: $input) {
      data
    }
  }
`;
export const _placeStudentPayment = gql`
  mutation PlaceStudentPayment($input: PlaceStudentPaymentIn) {
    placeStudentPayment(input: $input) {
      data
    }
  }
`;
export const _completeMultipleItemsPayment = gql`
  mutation CompleteMultipleItemsPayment($input: CompleteMultipleItemsPaymentIn) {
    completeMultipleItemsPayment(input: $input) {
      recipt {
        _id
      }
    }
  }
`;
